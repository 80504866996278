.header-container {
  position: fixed;
  top: 0%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: black;
  align-items: center;
  z-index: 1000;
  height: 5rem;
  border-bottom: .5px solid white;
  padding: 0rem 1rem;
}

/* .xrc-logo::after{
  content: "";
  background-color: white;
  height: 1px;
  width: 100%;
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  bottom: 0;
} */

.xrc-logo {
  width: 2rem;
}

.header-options {
  display: flex;
  color: #fff;
  font-family: "Clash Display Medium";
}

.header-link {
  cursor: pointer;
  font-size: .8rem;
}

.header-options-button {
  background: linear-gradient(180deg, #432BFF 0%, #6534FF 100%);
  font-size: 1rem;
  font-family: "Clash Display Bold";
  border: 2px solid #432BFF;
  letter-spacing: 0.025rem;
}
.header-options-button:hover{
  background:transparent;
  border: 2px solid white;
  transition-delay: .1s;
}

.logo-border {
  position: absolute;
  border-right: 0.5px solid #fff;
  /* width: calc(3% + 100px); */
  height: 5rem;
  top: 0;
  display: none;
}

@media (max-width:576px) {
  .xrc-logo-container {
    padding: 1rem;
  }
}

@media (min-width: 576px) {

  .header-container,
  .footer-container {
    padding: 2rem;
  }

  .xrc-logo {
    width: 2.5rem;
  }
}

@media (min-width: 768px) {

  .header-container,
  .footer-container {
    padding: 2rem;
  }

  .xrc-logo {
    width: 3rem;
  }

  .header-options-item {
    margin: 0rem 0rem 0rem 2rem;
    font-size: 1rem;
  }
  .header-link {
    cursor: pointer;
    font-size: .8rem;
  }
  .header-options-button {
    padding: 0.3rem 1.5rem !important;
  }

  .header-container {
    height: 6rem;
  }

  .logo-border {
    height: 6rem;
    margin-right: 1rem;
    display: block !important;
    left: 7.2rem;
  }
}

@media (min-width: 1200px) {

  .header-container,
  .footer-container {
    padding: 1rem 3rem;
  }

  .xrc-logo {
    width: 3.5rem;
  }

  .header-options-item {
    margin: 0rem 0rem 0rem 2.5rem;
    font-size: 1.1rem;
  }
  .header-link {
    cursor: pointer;
    font-size: .9rem;
  }

  .header-options-button {
    padding: 0.4rem 1.7rem !important;
  }

  .logo-border {
    left: 10.2rem;
  }
}

@media (min-width: 1400px) {

  .header-container,
  .footer-container {
    padding: 1.15rem 3rem;
  }

  .xrc-logo {
    width: 4rem;
  }

  .header-options-item {
    margin: 0rem 0rem 0rem 2rem;
    font-size: 1.2rem;
  }
  .header-link {
    cursor: pointer;
    font-size: 1rem;
  }

  .header-options-button {
    padding: 0.5rem 2rem !important;
  }

  .header-container {
    height: 7rem;
  }

  .logo-border {
    height: 7rem;
  }
}

@media (min-width: 1600px) {

  .header-container,
  .footer-container {
    padding: 1.5rem 4rem;
  }

  .xrc-logo {
    width: 4.5rem;
  }

  .header-options-item {
    margin: 0rem 0rem 0rem 3.35rem;
    font-size: 1.4rem;
  }
  .header-link {
    cursor: pointer;
    font-size: 1.2rem;
  }

  .header-options-button {
    padding: 0.6rem 2.2rem !important;
  }

  .logo-border {
    left: 12.2rem;
  }
}

@media (min-width: 1800px) {

  .header-container,
  .footer-container {
    padding: 2rem 5rem;
  }

  .xrc-logo {
    width: 5rem;
  }

  .header-options-item {
    margin: 0rem 0rem 0rem 3.5rem;
    font-size: 1.6rem;
  }
  .header-link {
    cursor: pointer;
    font-size: 1.4rem;
  }

  .header-options-button {
    padding: 0.7rem 2.4rem !important;
  }

  .header-container {
    height: 8rem;
  }

  .logo-border {
    left: 14.2rem;
    height: 8rem;
  }
}

ul.menu {
  position: relative;
  display: block;
  list-style: none;
  margin: 0 !important;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  text-align: right;
}

ul.menu li.menu-item {
  border-bottom: 1px solid #333;
  margin-top: 5px;
  transform: scale(1.15) translateY(-30px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.49s;
}

ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.42s;
}

ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.35s;
}

ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.28s;
}

ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.21s;
}

ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.14s;
}

ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.07s;
}

ul.menu li.menu-item a {
  display: block;
  position: relative;
  color: #FFF;
  font-family: "Clash Display Medium";
  font-weight: 100;
  text-decoration: none;
  font-size: 1.1rem;
  line-height: 2.35;
  font-weight: 200;
  width: 100%;
}

.menu-opened {
  height: 40rem;
  background-color: #000 !important;
  transition: all 0.3s ease-in, background 0.5s ease-in;
  transition-delay: 0.25s;
  visibility: visible !important;
}

.menu-opened .burger-container {
  transform: rotate(90deg);
}

.menu-opened .burger-container #burger .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
}

.menu-opened .burger-container #burger .bar.topBar {
  transform: translateY(4px) rotate(45deg);
}

.menu-opened .burger-container #burger .bar.btmBar {
  transform: translateY(3px) rotate(-45deg);
}

.menu-opened ul.menu li.menu-item {
  transform: scale(1) translateY(0px);
  opacity: 1;
}

.menu-opened ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.27s;
}

.menu-opened ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.34s;
}

.menu-opened ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.41s;
}

.menu-opened ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.48s;
}

.menu-opened ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.55s;
}

.menu-opened ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.62s;
}

.menu-opened ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.69s;
}

@media (min-width: 576px) {
  ul.menu {
    padding: 1rem 2.5rem 1.5rem 2.5rem !important;
  }
}