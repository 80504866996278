.logoMarqueeSection {
  /* padding-top: 10vh; */
  /* padding-bottom: 10vh; */
  overflow-x: hidden;
}

#logoMarqueeSection {
  max-width: 1920px !important;
  margin: 0 auto;
}

.default-content-container {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  width: 100%;
  min-height: 100vh;
}

div.marquee>a>img {
  padding: 8%;
  height: 80px;
}

.logoMarqueeSection>div>div {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
}

.marquee-wrapper {
  /* display:  inline-block; */
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  transform: translate3d(0%, 0, 0);
  animation-name: marquee;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.marquee a {
  display: inherit;
  white-space: nowrap;
  width: 15rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  border-left: .25px solid white;
  position: relative;
  text-align: center;
}
.brand-heading{
  background: #fff !important;
  /* border: 1px solid #000 !important; */
  box-shadow: 219px 0px 61px rgba(0, 0, 0, 0.02), 140px 0px 56px rgba(0, 0, 0, 0.15), 79px 0px 47px rgba(0, 0, 0, 0.5), 35px 0px 35px rgba(0, 0, 0, 0.85), 9px 0px 19px rgba(0, 0, 0, 0.98), 0px 0px 0px #000000;
  color: black !important ;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: Clash Display Bold;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
}
.l2, .l5, .l6{
  /* filter: grayscale(100%) !important;   */
}

.marqueelogo {
  min-width: 10%;
  padding: 5%;
  /* border-left: 1px solid white; */
  max-width: 10%;
  align-self: center;
  justify-self: center;
  /* filter: brightness(0) invert(.5); */
}


@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@media (min-width: 768px) {
  .brand-heading {
    min-width: 15rem;
  }
}