.about {
    /* border-bottom: 2px solid white; */
    position: relative;
    border-top: none !important;
    margin-top: 5rem;
    height: calc(100vh - 5.1rem) !important;
}

:root {
    --base-font-title: 2.1rem;
    --base-font-subtitle: .8rem;
    --base-title-line: 2.4rem;
    --base-subtitle-line: 1rem;
    --refresh-btn: 4rem;
    --angle: 45deg;
    --opacity: 0.5;
    --bgcolor: #222021
}
.logo-image{
    position: absolute;
    right: 0px !important;
    bottom: 0px !important;
    padding: 1rem !important;
}

.logo-image img {
    max-height: 1.5rem;
    width: auto;
}

.row {
    margin: 0 !important;
}

.about-title {
    font-family: 'Clash Display Bold';
    font-size: var(--base-font-title);
    color: white;
    text-align: left;
    font-weight: 700;
    line-height: 3rem;
    text-align: center;
}

.about-title-alt {
    font-size: 1.125rem !important
}


.about-subtitle {
    font-family: 'Clash Display Light';
    font-size: var(--base-font-subtitle);
    color: white;
    text-align: left;
    font-weight: 400;
    /* line-height: 1rem; */
    text-align: center;
    font-size: 0.9rem;
}

.about-img {
    width: 100%;
}

.about-container {
    position: relative;
    border-top: .5px solid #fff;
    padding: 5%;
    display: flex;
    flex-direction: column;
    order: 1;
    justify-content: center;
}

.about-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 0;
    /* margin-top:10%; */
    height: auto !important;
}

.refresh-btn-mob {
    width: var(--refresh-btn);
    height: var(--refresh-btn);;
    background: #454545;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    transform: translateY(50%);
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    padding: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    z-index: 20;
    /* box-shadow: 0px 53px 21px rgba(146, 47, 216, 0.02), 0px 30px 18px rgba(146, 47, 216, 0.07), 0px 13px 13px rgba(146, 47, 216, 0.13), 0px 3px 7px rgba(146, 47, 216, 0.15), 0px 0px 0px rgba(146, 47, 216, 0.15); */
}

.refresh-btn-des {
    width: var(--refresh-btn);
    height: var(--refresh-btn);
    background: linear-gradient(180deg, #432BFF 0%, #6534FF 100%);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translateX(-50%);
    display: flex;
    justify-self: center;
    justify-content: center;
    user-select: none;
    /* animation: refreshBtn 1s linear; */
    /* box-shadow: 0px 53px 21px rgba(146, 47, 216, 0.02), 0px 30px 18px rgba(146, 47, 216, 0.07), 0px 13px 13px rgba(146, 47, 216, 0.13), 0px 3px 7px rgba(146, 47, 216, 0.15), 0px 0px 0px rgba(146, 47, 216, 0.15); */
}

.bounce-in-bottom-1 {
	-webkit-animation: slide-in-fwd-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.bounce-in-bottom-2 {
	-webkit-animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.bounce-in-bottom-3 {
	-webkit-animation: slide-in-fwd-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.bounce-in-bottom-4 {
	-webkit-animation: slide-in-fwd-bottom 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bottom 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.bounce-in-bottom-5 {
	-webkit-animation: slide-in-fwd-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * ----------------------------------------
 * animation slide-in-fwd-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-40px) translateY(50px);
              transform: translateZ(-40px) translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-40px) translateY(50px);
              transform: translateZ(-40px) translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  


/* ---------------------------------------------
 * ----------------------------------------
 * animation bounce-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-in-bottom {
    0% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(65px);
              transform: translateY(65px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(28px);
              transform: translateY(28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes bounce-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(65px);
              transform: translateY(65px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(28px);
              transform: translateY(28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  

.icon {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 50%;
    z-index: 2;
}

.text {
    position: absolute;
    height: 100%;
    width: 100%;
    /* animation: textRotate 10s linear infinite; */
}
.refresh-btn-des:hover .text{
    animation-play-state: paused;
}


@keyframes textRotate {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes iconRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.text span {
    position: absolute;
    font-family: 'Clash Display Medium';
    left: 50%;
    color: white;
    font-size: .5rem;
    transform-origin: 0 2rem;
}

@media (min-width: 576px) {
    .about-container {
        border-right: .5px solid white !important;
        order: 0;
    }
    .about-img-container {
        order: 1;
    }
    .about-title, .about-subtitle {
        text-align: unset;
    }
}

@keyframes refreshBtn {
    0% {
        top: 0;
    }

    100% {
        top: 60%;
        transform: translateX(-50%);
    }
}

@media (min-width: 576px) {
    .about-container {
        border-right: .5px solid white !important;
        border-bottom: none;
        transition: border-right 2s;
        animation: borderRight 1.5s linear;
    }

    /* .refresh-btn-des::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: white;
        top: 0;
        transform: rotate(90deg) translate(-50%,100%);

    }
    .refresh-btn-des::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: white;
        bottom: 0;
        transform: rotate(90deg) translate(50%,100%);
    } */
}



@media (min-width: 768px) {
    .about-title-alt {
        font-size: 2.125vw !important
    }

    .about-title {
        font-size: calc(var(--base-font-title)*1.2);
        line-height: calc(var(--base-title-line)*1.2);
    }

    .about-subtitle {
        font-size: calc(var(--base-font-subtitle)*1.2);
        line-height: calc(var(--base-subtitle-line)*1.2);
    }

    .refresh-btn-des {
        width: calc(var(--refresh-btn)*1.2);
        height: calc(var(--refresh-btn)*1.2);
    }

    .about-container {
        border-bottom: none !important;
        border-top: none !important;
    }

    .about {
        margin-top: 6rem;
        height: calc(100vh - 6.1rem) !important;
    }
    .about-img-container {
        height: 100% !important;
    }
}

@media (min-width: 992px) {
    .about-title {
        font-size: calc(var(--base-font-title)*1.4);
        line-height: calc(var(--base-title-line)*1.4);
    }

    .about-subtitle {
        font-size: calc(var(--base-font-subtitle)*1.4);
        line-height: calc(var(--base-subtitle-line)*1.4);
    }

    .refresh-btn-des {
        width: calc(var(--refresh-btn)*1.4);
        height: calc(var(--refresh-btn)*1.4);
    }

}

@media (min-width: 1200px) {
    .about-title {
        font-size: calc(var(--base-font-title)*1.6);
        line-height: calc(var(--base-title-line)*1.6);
    }

    .about-subtitle {
        font-size: calc(var(--base-font-subtitle)*1.6);
        line-height: calc(var(--base-subtitle-line)*1.6);
    }

    .refresh-btn-des {
        width: calc(var(--refresh-btn)*1.6);
        height: calc(var(--refresh-btn)*1.6);
    }


}

@media (min-width: 1400px) {
    .about-title {
        font-size: calc(var(--base-font-title)*1.8);
        line-height: calc(var(--base-title-line)*1.8);
    }

    .about-subtitle {
        font-size: calc(var(--base-font-subtitle)*1.8);
        line-height: calc(var(--base-subtitle-line)*1.8);
    }

    .refresh-btn-des {
        width: calc(var(--refresh-btn)*1.8);
        height: calc(var(--refresh-btn)*1.8);
    }

    .about {
        margin-top: 7rem;
        height: calc(100vh - 7.1rem) !important;
    }
}

@media (min-width: 1600px) {
    .about-title {
        font-size: calc(var(--base-font-title)*2);
        line-height: calc(var(--base-title-line)*2);
    }

    .about-subtitle {
        font-size: calc(var(--base-font-subtitle)*2);
        line-height: calc(var(--base-subtitle-line)*2);
    }

    .refresh-btn-des {
        width: calc(var(--refresh-btn)*2);
        height: calc(var(--refresh-btn)*2);
    }

}

@media (min-width: 1800px) {
    .about-title {
        font-size: calc(var(--base-font-title)*2.2);
        line-height: calc(var(--base-title-line)*2.2);
    }

    .about-subtitle {
        font-size: calc(var(--base-font-subtitle)*2.2);
        line-height: calc(var(--base-subtitle-line)*2.2);
    }

    .refresh-btn-des {
        width: calc(var(--refresh-btn)*2.2);
        height: calc(var(--refresh-btn)*2.2);
    }

    .about {
        margin-top: 8rem;
        height: calc(100vh - 8.1rem) !important;
    }
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.overlay.show .bar {
    transform: scale(1, 1) translate3d(0, 0, 0);
    transform-origin: 0 0;
}

.overlay .bar {
    position: absolute;
    width: 10.1vw;
    background: #000 !important;
    top: 0;
    bottom: 0;
    transform: scale(0, 1) translate3d(0, 0, 0);
    transform-origin: 100% 100%;
    transition: transform 0.5s ease-in-out;
}

.overlay .bar:first-of-type {
    border-left: none;
}

.overlay .bar:nth-of-type(1) {
    left: 0vw;
    background: linear-gradient(to right, var(--bgcolor) 0%,  var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.05s;
}

.overlay .bar:nth-of-type(2) {
    left: 10vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.1s;
}

.overlay .bar:nth-of-type(3) {
    left: 20vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.15s;
}

.overlay .bar:nth-of-type(4) {
    left: 30vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.2s;
}

.overlay .bar:nth-of-type(5) {
    left: 40vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.25s;
}

.overlay .bar:nth-of-type(6) {
    left: 50vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.3s;
}

.overlay .bar:nth-of-type(7) {
    left: 60vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.35s;
}

.overlay .bar:nth-of-type(8) {
    left: 70vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.4s;
}

.overlay .bar:nth-of-type(9) {
    left: 80vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.45s;
}

.overlay .bar:nth-of-type(10) {
    left: 90vw;
    background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
    opacity: 1;
    transition-delay: 0.5s;
}

@media (min-width: 576px) {
    .overlay .bar {
        position: absolute;
        width: 5.1vw;
        background: #7843e8;
        top: 0;
        bottom: 0;
        transform: scale(0, 1) translate3d(0, 0, 0);
        transform-origin: 100% 100%;
        transition: transform 0.5s ease-in-out;
    }
    .overlay .bar:nth-of-type(1) {
        left: 0vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.05s;
    }
    
    .overlay .bar:nth-of-type(2) {
        left: 5vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.1s;
    }
    
    .overlay .bar:nth-of-type(3) {
        left: 10vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.15s;
    }
    
    .overlay .bar:nth-of-type(4) {
        left: 15vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.2s;
    }
    
    .overlay .bar:nth-of-type(5) {
        left: 20vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.25s;
    }
    
    .overlay .bar:nth-of-type(6) {
        left: 25vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.3s;
    }
    
    .overlay .bar:nth-of-type(7) {
        left: 30vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.35s;
    }
    
    .overlay .bar:nth-of-type(8) {
        left: 35vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.4s;
    }
    
    .overlay .bar:nth-of-type(9) {
        left: 40vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.45s;
    }
    
    .overlay .bar:nth-of-type(10) {
        left: 45vw;
        background: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 20%);
        opacity: 1;
        transition-delay: 0.5s;
    }
}

.fadeOut{
    opacity:0;
    /* width:0;
    height:0; */
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn{
    opacity:1;
    /* width:100px;
    height:100px; */
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.2s;
}

.video-subtitle {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    user-select: none;
}


.video-subtitle > div {
    font-size: 0.55rem;
}

@media (min-width: 768px) {
    .video-subtitle > div {
        font-size: 0.75rem !important;
    }
}

.video-subtitle > div {
    display: flex;
    justify-content: space-between;
}

#video-class, .spinner-container {
    height: 330px !important;
    z-index: 10;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-title-container {
    justify-content: center;
}

@media (min-width: 576px) {
    #video-class, .spinner-container {
        height: 100% !important;
    }

    .about-title-container {
        justify-content: left;
    }
}

.model-bg {
    position: absolute;
    height: auto !important;
    filter: grayscale(1);
    user-select: none;
}

.mob-click-here {
    font-size: 0.45rem;
    font-family: 'Clash Display SemiBold';
    margin: auto;
    letter-spacing: 0.03rem;
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
}

.des-click-here {
    font-size: 0.45rem;
    font-family: 'Clash Display SemiBold';
    margin: auto;
    letter-spacing: 0.04rem;
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
}

@media (min-width: 992px) {
    .des-click-here {
        font-size: 0.6rem;
        top: 50%;
        transform: translateY(-48%);
    }
}

@media (min-width: 1200px) {
    .des-click-here {
        font-size: 0.7rem;
        top: 50%;
        transform: translateY(-46%);
    }
}

@media (min-width: 1500px) {
    .des-click-here {
        font-size: 0.8rem;
        top: 50%;
        transform: translateY(-47%);
    }
}

@media (min-width: 1700px) {
    .des-click-here {
        font-size: 0.9rem;
        top: 50%;
        transform: translateY(-46%);
    }
}

.spinner-border {
    color: #fff;
    position: absolute;
    margin: auto;
    z-index: 100;
}

.no-mt {
    margin-top: 0 !important;
}