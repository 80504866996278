:root{
    --font-description-size:20px;
    --font-description-line-height:30px;
    --font-title-size:40px;
    --font-title-line-height:59px;
    --font-number-size:24px;
    --font-number-line-height:30px;
    --font-button-size:20px
    --font-button-line-height:30px;
}

.solution-container{
    position: relative;
    margin-top: 5rem;
    border-bottom: 1px solid #fff;
    min-height: 100vh;
}
.solution-img{
    padding-top: 20%;
    /* margin-left: 30%; */
    width: 100%;
    position: relative;
    margin-bottom: 15%;
    border-bottom: 1px solid #fff;;
    align-items: center;
    display: flex;
    justify-content: center;
}
.img-top{
    position: absolute;
    bottom: -37%;
}
.solution-number{
    color: white !important;
    font-family: 'Clash Display Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
}
.solution-title{
    font-family: 'Clash Display Medium';
    font-weight: 300;
    font-size: 48px;
    line-height: 59px;
    text-transform: capitalize;
    color: #fff !important;
}
.solution-description{
    font-family: 'Clash Display Light';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #fff !important;
    text-align: justify;
}
.container-content{
    margin-left: 10% !important;
    margin-right: 10% !important;
    margin-bottom: 7% !important;
    margin-bottom: 0;
    width: 80% !important;
}
.solution-content-button{
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: white !important;
    font-family: 'Clash Display Medium';
    margin-bottom: 5%;
}
.image-content{
    width: 100%;
    border-radius:10px !important;
}

.image-content:hover{
    filter: grayscale(0);
}
.solution-content-button:hover{
    color: #924de2 !important;
}
.solution-content-button:hover > span{
    /* display: block !important; */
}

@media (max-width: 576px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 0.8);
        line-height: calc(var(--font-number-line-height) * 0.8);
    }
    .solution-img{
        margin-bottom: 20%;
    }
    .image-content{
        border-radius: 10px !important;
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 0.8);
        line-height: calc(var(--font-title-line-height) * 0.8);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 0.8);
        line-height: calc(var(--font-description-line-height) * 0.8);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 0.8);
        line-height: calc(var(--font-button-line-height) * 0.8);
    }
    .solution-img-bg{
        width: 80% !important;
    }
    .container-content{
        margin-bottom: 20% !important;
    }
    .img-top{
        width: 60% !important;
        bottom: -40% !important;
    }
}
@media (min-width: 768px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 0.8);
        line-height: calc(var(--font-number-line-height) * 0.8);
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 0.8);
        line-height: calc(var(--font-title-line-height) * 0.8);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 0.8);
        line-height: calc(var(--font-description-line-height) * 0.8);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 0.8);
        line-height: calc(var(--font-button-line-height) * 0.8);
    }
    .image-content{
        border-radius: 10px !important;
    }

}
@media (min-width: 992px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 1);
        line-height: calc(var(--font-number-line-height) * 1);
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 1);
        line-height: calc(var(--font-title-line-height) * 1);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 1);
        line-height: calc(var(--font-description-line-height) * 1);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 1);
        line-height: calc(var(--font-button-line-height) * 1);
    }
}
@media (min-width: 1200px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 1);
        line-height: calc(var(--font-number-line-height) * 1);
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 1);
        line-height: calc(var(--font-title-line-height) * 1);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 1);
        line-height: calc(var(--font-description-line-height) * 1);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 1);
        line-height: calc(var(--font-button-line-height) * 1);
    }
}
@media (min-width: 1400px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 1);
        line-height: calc(var(--font-number-line-height) * 1);
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 1);
        line-height: calc(var(--font-title-line-height) * 1);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 1);
        line-height: calc(var(--font-description-line-height) * 1);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 1);
        line-height: calc(var(--font-button-line-height) * 1);
    }
}
@media (min-width: 1600px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 1.2);
        line-height: calc(var(--font-number-line-height) * 1.2);
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 1.2);
        line-height: calc(var(--font-title-line-height) * 1.2);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 1.2);
        line-height: calc(var(--font-description-line-height) * 1.2);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 1.2);
        line-height: calc(var(--font-button-line-height) * 1.2);
    }
}
@media (min-width: 1800px){
    .solution-number{
        font-size: calc(var(--font-number-size) * 1.4);
        line-height: calc(var(--font-number-line-height) * 1.4);
    }
    .solution-title{
        font-size: calc(var(--font-title-size) * 1.4);
        line-height: calc(var(--font-title-line-height) * 1.4);
    }
    .solution-description{
        font-size: calc(var(--font-description-size) * 1.4);
        line-height: calc(var(--font-description-line-height) * 1.4);
    }
    .solution-button{
        font-size: calc(var(--font-button-size) * 1.4);
        line-height: calc(var(--font-button-line-height) * 1.4);
    }
}