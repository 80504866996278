.footer-container {
  color: #fff !important;
  background-color: black;
  width: 100%;
  padding: 1rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /* border-top: 1px solid #fff; */
}
.footer-options-title {
  font-size: 1.8rem;
  font-family: "Clash Display SemiBold";
}

.footer-subscribe-form {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #422BFF;
  width: 100%;

}
.f-1 {
	-webkit-animation: slide 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.f-2 {
	-webkit-animation: slide 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.f-3 {
	-webkit-animation: slide 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.f-4 {
	-webkit-animation: slide 1.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide 1.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.f-5 {
	-webkit-animation: slide 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateZ(-100px) translateY(100px);
            transform: translateZ(-100px) translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide {
  0% {
    -webkit-transform: translateZ(-100px) translateY(100px);
            transform: translateZ(-100px) translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.footer-subscribe-text {
  font-family: "Clash Display Light";
  font-size: 0.9rem;
  background: transparent;
  border: none;
  color: #fff;
  width: 65%;
  margin-top: 0 !important;
}

.footer-subscribe-text::placeholder {
  color: #fff;
  opacity: 1;
}
.icons{
  /* height: 40px; */
  background-color: white;
  /* width: 40px; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .8rem;
  font-size: 1.5rem;
  padding: .5rem;
  height: 3rem;
  width: 3rem;
}
.icons:hover{
  background-color: black;
  border: 1px solid white;
}
.icons:hover svg{
  color: white !important;
}


.footer-subscribe-button {
  font-family: "Clash Display Bold";
  background: linear-gradient(180deg, #432BFF 0%, #6534FF 100%);
  font-size: 0.95rem;
  padding: 0.8rem 1rem;
  color: white !important;
  border: 2px solid #6534FF;
  letter-spacing: 0.025rem;
}
.footer-subscribe-button:hover{
  background:transparent;
  border: 2px solid white;
  transition-delay: .1s;
}

.footer-logo-container {
  height: 90% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  cursor: pointer;
  width: 10rem;
  margin: auto;
}

.footer-logo-subtitle {
  font-family: "Clash Display Light";
  font-size: 0.8rem;
}

.footer-links {
  font-family: "Clash Display Medium";
}


.social-icons {
  color: black;
  background-color: #fff;
  margin: 0rem 0.5rem;
  padding: 0.3rem;
  display: block;
  width: 2.5rem;
  height: auto;
  border-radius: 100%;
}

textarea:focus, input:focus{
  outline: none;
}
textarea:autofill, input:autofill{
  outline: none;
  background-color: transparent;
}

.footer-links > div, .social-icons {
  cursor: pointer;
}
@media (max-width: 400px) {
  .icons{
    margin:.5rem
  }
  
}
@media (min-width: 425px) {
  .footer-options-title {
    font-size: 2.3rem;
  }
  .footer-logo {
    width: 12rem;
  }
  .footer-logo-subtitle {
    font-family: "Clash Display Light";
    font-size: 0.95rem;
  }
  .footer-subscribe-text {
    font-size: 0.95rem;
  }
}

@media (min-width: 576px) {
  .footer-options-title {
    font-size: 3rem;
  }
  .footer-logo {
    width: 14rem;
  }
  
  .footer-logo-subtitle {
    font-family: "Clash Display Light";
    font-size: 1.1rem;
  }
  .social-icons {
    margin: 0rem 0.8rem;
    padding: 0.5rem;
    font-size: 2rem;
    width: 3rem;
    height: auto;
  }
  .footer-links {
    font-size: 1.1rem;
  }
  .footer-subscribe-text {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  .footer-options-container {
    border-right: 0.5px solid #fff;
  }
  .footer-container {
    padding-right: 0 !important;
  }
  .footer-options-title {
    font-size: 2.8rem;
  }
  .footer-logo {
    width: 11rem;
    margin: unset;
  }
  .footer-logo-subtitle {
    font-family: "Clash Display Light";
    font-size: 0.855rem;
  }
  .social-icons {
    margin: 0rem 1.2rem 0rem 0rem;
    padding: 0.4rem;
    font-size: 1.4rem;
    width: 2.4rem;
    height: auto;
  }
  .footer-links > div {
    font-size: 0.855rem;
    margin-right: 2rem;
  }
  .footer-subscribe-form {
    width: 22rem;
  }
  .footer-subscribe-text {
    font-size: 0.855rem;
  }
}

@media (min-width: 992px) {
  .footer-options-title {
    font-size: 3.3rem;
  }
  .footer-logo {
    width: 12rem;
  }
  
  .footer-logo-subtitle {
    font-family: "Clash Display Light";
    font-size: 0.95rem;
  }
  .social-icons {
    margin: 0rem 1.5rem 0rem 0rem;
    padding: 0.5rem;
    font-size: 2.5rem;
    width: 1.5rem;
    height: auto;
  }
  .footer-links > div {
    font-size: 0.95rem;
    margin-right: 3rem;
  }
  .footer-subscribe-text {
    font-size: 0.95rem;
  }
  .footer-subscribe-form {
    width: 25rem;
  }
}

@media (min-width: 1200px) {
  .footer-options-title {
    font-size: 3.8rem;
  }
  .footer-logo {
    width: 13rem;
  }
  
  .footer-logo-subtitle {
    font-family: "Clash Display Light";
    font-size: 1.05rem;
  }
  .social-icons {
    margin: 0rem 1.6rem 0rem 0rem;
    padding: 0.6rem;
    font-size: 1.5rem;
    width: 2.5rem;
    height: auto;
  }
  .footer-links > div {
    font-size: 1.05rem;
    margin-right: 3rem;
  }
  .footer-subscribe-text {
    font-size: 1.05rem;
  }
  .footer-subscribe-form {
    width: 28rem;
  }
}

@media (min-width: 1400px) {
  .footer-options-title {
    font-size: 4.5rem;
  }
  .footer-logo {
    width: 14rem;
  }
  
  .footer-logo-subtitle {
    font-family: "Clash Display Light";
    font-size: 1.1rem;
  }
  .social-icons {
    margin: 0rem 2.2rem 0rem 0rem;
    padding: 0.6rem;
    font-size: 1.6rem;
    width: 2.6rem;
    height: auto;
  }
  .footer-links > div {
    font-size: 1.1rem;
    margin-right: 3rem;
  }
  .footer-subscribe-text {
    font-size: 1.1rem;
  }
  .footer-subscribe-form {
    width: 32rem;
  }
}

@media (min-width: 1600px) {
  .footer-options-title {
    font-size: 4.9rem;
  }
  .footer-logo {
    width: 14rem;
  }
  
  .footer-logo-subtitle {
    font-size: 1.15rem;
  }
  .social-icons {
    margin: 0rem 2rem 0rem 0rem;
    padding: 0.6rem;
    font-size: 1.7rem;
    width: 2.7rem;
    height: auto;
  }
  .footer-links > div {
    font-size: 1.15rem;
    margin-right: 2.9rem;
  }
  .footer-subscribe-text {
    font-size: 1.15rem;
  }
  .footer-subscribe-form {
    width: 35rem;
  }
}

@media (min-width: 1800px) {
  .footer-options-title {
    font-size: 5.45rem;
  }
  .footer-logo {
    width: 16rem;
  }
  
  .footer-logo-subtitle {
    font-size: 1.255rem;
  }
  .social-icons {
    margin: 0rem 2rem 0rem 0rem;
    padding: 0.7rem;
    font-size: 2rem;
    width: 3rem;
    height: auto;
  }
  .footer-links > div {
    font-size: 1.255rem;
    margin-right: 4rem;
  }
  .footer-subscribe-text {
    font-size: 1.255rem;
  }
  .footer-subscribe-form {
    width: 39rem;
  }
}

/* @keyframes fup1 {
  0% {
    opacity: 0;
    color: red;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    color: #fff;
    transform: translateY(0%);
  }
}

.new-0 {
  animation: fup1 .3s ease-in 0s;
}
.new-1 {
  animation: fup1 .3s ease-in 0.5s;
}
.new-2 {
  animation: fup1 .3s ease-in .3s;
}
.new-3 {
  animation: fup1 .3s ease-in 1.5s;
}
.new-4 {
  animation: fup1 .3s ease-in 2s;
}
.new-5 {
  animation: fup1 .3
  s ease-in 2.5s;
} */